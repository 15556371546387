/**
 * Isomorphic settings file (hence not typescript and commonjs notation)
 */

module.exports.urls = {
  authors: 'authors',
  articles: 'articles',
  netlify: {
    preview: '/.netlify/functions/preview',
  },
  linkedin: 'https://www.linkedin.com/in/async-inc-4b3872218/',
  instagram: 'https://www.instagram.com/async.inc/',
  dribbble: 'https://dribbble.com/Clubb/about',
  twitter: 'https://twitter.com/async_inc',
  facebook: 'https://www.facebook.com',
}

module.exports.imageQuality = 100

/**
 * What size do we want to cut hero images to?
 */

module.exports.heroImageDimensions = [
  ['CardPreview', 1200, 680],
  ['Article__Featured', 1200, 825],
  ['Article__Hero', 3200, 2200],
]
